import React from "react"

import kebabCase from "lodash/kebabCase"

import { Link, graphql } from "gatsby"
import { SEO } from "../components/seo";
import { Layout } from "../components/layout"

interface Props {
    data: {
        allMarkdownRemark: {
            group: {
                fieldValue: string,
                totalCount: number
            }[]
        },
        site: {
            siteMetadata: {
                title: string
            }
        }
    },
    location: Location
}

const TagsPage = ({ data, location }: Props) => {
    const title = data.site.siteMetadata.title
    const tags = data.allMarkdownRemark.group

    return (
        <Layout location={location} title={title}>
            <SEO title="Alla taggar" />
            <h1 className="text-5xl mb-4 capitalize">Alla taggar</h1>

            <div className="max-w-2xl mx-auto">
                <ul>
                    {tags.map((tag) => (
                        <li key={tag.fieldValue} className="text-xl">
                            <Link to={`/tags/${kebabCase(tag.fieldValue)}/`}>
                                {tag.fieldValue} ({tag.totalCount})
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>
        </Layout>
    )
}

export default TagsPage

export const pageQuery = graphql`
  query TagsQuery{
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(limit: 2000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`